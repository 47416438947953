@import "~antd/dist/antd.dark.less"; // Introduce the official dark less style entry file

.App {
  display: flex;
  justify-content: center;
}

.ant-card {
  border: none;
  border-radius: 12px;

  .ant-card-head {
    border: none;
  }
}

.ant-btn {
  border-radius: 8px;
}

.ant-divider {
  border: 0.5px solid #c4c4c4;
}

.ant-dropdown-menu {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);

  .ant-dropdown-menu-item-active {
    color: initial;
  }
}
.ant-modal {
  background: #3a3a3a;
  border: 1px solid #808080;
  border-radius: 16px;
  overflow: hidden;
  padding: 0;
  min-width: 448px;

  .ant-modal-header {
    background: #3a3a3a;
    border-bottom: none;
  }

  .ant-modal-body {
    background: #3a3a3a;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: 200px;
    padding: 16px;
  }

  .ant-modal-footer {
    background: #3a3a3a;
    border: none;
  }

  .ant-result {
    display: flex;
    flex-direction: column;
  }

  .ant-result-title {
    order: 1;
    font-weight: 700;
    margin-bottom: 32px;
  }

  .ant-result-icon {
    order: 2;

    svg {
      height: 32px;
    }
  }

  .ant-result-extra {
    order: 3;
    margin: 0;

    .ant-btn-link {
      font-weight: 700;
    }
  }
}

.ant-table {
  background: none !important;

  .ant-table-cell {
    background: none !important;
  }
}

.action-input {
  color: #ffffff;
  font-size: 40px;
  max-width: 250px;
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@media screen and (max-width: 768px) {
  .ant-modal {
    margin: 0;
    width: 100vw !important;
    height: calc(100vh - 30px);
    min-width: 300px;
    max-width: 100vw;
    box-shadow: none;
    position: fixed;
    top: 95px;
    .ant-modal-content {
      box-shadow: none;
    }
  }
  .ant-menu {
    width: calc(100vw - 60px);
    margin: 0 !important;
  }
}
.ant-tabs-tab-btn {
  color: #808080;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}
.card-container .ant-tabs-tab {
  width: calc(50vw - 50px);
  text-align: center;
  display: flex;
  justify-content: center;
}
.card-container .ant-tabs-tab-active {
  width: calc(50vw - 50px);
  text-align: center;
  display: flex;
  justify-content: center;
}

